import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

// Crear un componente MenuItem
const MenuItem = ({ text, itemRef }) => {
    const { isVisible } = React.useContext(VisibilityContext);

    return (
        <div ref={itemRef} className={`menu-item ${isVisible ? 'active' : ''}`}>
            {text}
        </div>
    );
};

// Crear una lista de elementos del menú
const list = Array(50)
    .fill(0)
    .map((_, ind) => ({ id: `element-${ind}` }));

// Crear un componente Menu
const Menu = ({ data, selected }) => (
    <ScrollMenu
        data={data.map((el) => (
            <MenuItem text={el.id} key={el.id} />
        ))}
        selected={selected}
    />
);

// Usar el componente Menu en el componente App
function HorizontalScroll() {
    return <Menu className="ScrollMenu__Wrapper" data={list} selected={[]} />;
}

export default HorizontalScroll;