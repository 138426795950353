import React, { Component } from 'react';
import Capa0 from "../multimedia/parallaxImages/Capa0.png";
import Capa1 from "../multimedia/parallaxImages/Capa1.png";
import Capa2 from "../multimedia/parallaxImages/Capa2.png";
import Capa3 from "../multimedia/parallaxImages/Capa3.png";
import Capa4 from "../multimedia/parallaxImages/Capa4.png";
import Capa5 from "../multimedia/parallaxImages/Capa5.png";

class Parallax extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layers: Array(6).fill({ x: 0, y: 0 }),
        };
        this.layersRef = Array(6).fill().map(() => React.createRef());
    }

    componentDidMount() {
        if (window.DeviceOrientationEvent && typeof window.orientation !== "undefined") {
            window.addEventListener('deviceorientation', this.handleOrientation);
        } else {
            document.addEventListener('mousemove', this.handleMouseMove);
        }
    }

    componentWillUnmount() {
        if (window.DeviceOrientationEvent && typeof window.orientation !== "undefined") {
            window.removeEventListener('deviceorientation', this.handleOrientation);
        } else {
            document.removeEventListener('mousemove', this.handleMouseMove);
        }
    }

    handleOrientation = (event) => {
        const { alpha, beta, gamma } = event;
        // alpha: rotación alrededor del eje Z
        // beta: rotación alrededor del eje X
        // gamma: rotación alrededor del eje Y
        // puedes usar estos valores para mover tus imágenes
        this.setState((prevState) => ({
            layers: prevState.layers.map((layer, i) => {
                const layerRef = this.layersRef[i].current;
                if (layerRef) {
                    const speed = (i + 1) * 0.03; // Ajusta la velocidad de cada capa aquí
                    const x = beta * speed; // puedes ajustar estos valores según tus necesidades
                    const y = gamma * speed; // puedes ajustar estos valores según tus necesidades
                    return { x, y };
                }
                return layer;
            }),
        }));
    };

    handleMouseMove = (event) => {
        const { clientX, clientY } = event;
        this.setState((prevState) => ({
            layers: prevState.layers.map((layer, i) => {
                const layerRef = this.layersRef[i].current;
                if (layerRef) {
                    const speed = (i + 1) * 0.03; // Ajusta la velocidad de cada capa aquí
                    const x = (layerRef.offsetWidth / 2 - clientX) * speed;
                    const y = (layerRef.offsetHeight / 2 - clientY) * speed;
                    return { x, y };
                }
                return layer;
            }),
        }));
    };

    render() {
        const images = [Capa5, Capa4, Capa3, Capa2, Capa1, Capa0];
        return (
            <div className='-mt-16 relative scale-125 w-screen h-screen overflow-hidden overflow-x-hidden overflow-y-hidden'>
            {this.state.layers.map((layer, i) => (
                    <img
                        ref={this.layersRef[i]}
                        key={i}
                        src={images[i]} // Usando las imágenes importadas
                        alt={`layer${i}`}
                        style={{
                            position: 'absolute',
                            transform: `translate3d(${layer.x}px, ${layer.y}px, 0)`,
                            
                        }}
                    />
                ))}
            </div>
        );
    }
}

export default Parallax;