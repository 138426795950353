import "./App.css";
import HorizontalScroll from "./components/HorizontalScroll";
import Parallax from "./components/Parallax";

function App() {
  return (
    <>
      <div className="w-screen h-screen overflow-hidden">
        <Parallax />
        
      </div>
      {/* <div className="menu-item">
        <h1>Mi Aplicación</h1>
        <h2>Sección 1</h2>
        <HorizontalScroll />
        <h2>Sección 2</h2>
        <HorizontalScroll />
        <h2>Sección 3</h2>
        <HorizontalScroll />
        {Array.from({ length: 47 }, (_, index) => (
          <>
            <h2 key={index+401}>Sección {index + 4}</h2>
            <HorizontalScroll key={index + 4} />
          </>
        ))}
      </div> */}
    </>
  );
}

export default App;
